<template>
  <el-main class="realname">
    <el-row>
      <el-col>
        <el-card v-if="Number(userInfo.cert) === 0">
          <template #header>
            <div>
              <span>实名认证</span>
            </div>
          </template>
          <el-form ref="realNameForm" :model="realNameForms" label-width="80px">
            <el-form-item label="真实姓名">
              <el-input placeholder="请输入真实姓名" v-model="realNameForms.realname"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input placeholder="请输入身份证号" v-model="realNameForms.idcard"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input disabled placeholder="请输入手机号" clearable v-model="realNameForms.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input placeholder="请输入验证码" v-model="realNameForms.captcha" auto-complete="off">
                <template #append>
                  <el-button @click="handleSms" :loading="sendSms">获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="QQ">
              <el-input placeholder="请输入QQ" v-model="realNameForms.qq"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input placeholder="请输入邮箱" v-model="realNameForms.email"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="活动区域">
              <el-select v-model="form.region" placeholder="请选择活动区域">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="活动时间">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="即时配送">
              <el-switch v-model="form.delivery"></el-switch>
            </el-form-item>
            <el-form-item label="活动性质">
              <el-checkbox-group v-model="form.type">
                <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                <el-checkbox label="地推活动" name="type"></el-checkbox>
                <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="特殊资源">
              <el-radio-group v-model="form.resource">
                <el-radio label="线上品牌商赞助"></el-radio>
                <el-radio label="线下场地免费"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="活动形式">
              <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="submit">提交实名</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card class="cert-success" v-else>
          <em :class="Number(userInfo.cert) !== 1 ? 'el-icon-success' : 'el-icon-warning'"></em>
          <h1>{{ Number(userInfo.cert) !== 1 ? '您已通过了实名认证' : '您的实名信息正在审核，请耐心等候' }}</h1>
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
interface IRealNameForms {
  [key: string]: string;
  realname: string;
  idcard: string;
  mobile: string;
  captcha: string;
}
export default defineComponent({
  name: 'Shorten',
  setup () {
    const store = useStore()
    const { proxy }: any = getCurrentInstance()
    const sendSms = ref(false)
    const userInfo = computed(() => store.getters.GET_USER_INFO)
    const realNameForms = ref<IRealNameForms>({
      realname: '',
      idcard: '',
      mobile: '',
      captcha: ''
    })

    const submit = (): void => {
      proxy.$axios.post('/user/cert', realNameForms.value)
        .then((res: IResponse) => {
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
            store.commit('SET_USER_INFO', res.data)
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
          for (const i in realNameForms.value) {
            realNameForms.value[i] = ''
          }
        })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = realNameForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'cert'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    onMounted(() => {
      realNameForms.value.mobile = userInfo.value && userInfo.value.mobile
    })

    return {
      realNameForms,
      userInfo,
      submit,
      sendSms,
      handleSms
    }
  }
})
</script>

<style lang="scss" scoped>
  .realname {
    background: #F3F4F6;
  }
  .cert-success {
    width: 100%;
    min-height: calc(100vh - 162px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    em {
      font-size: 90px;
      margin-bottom: 24px;
    }
    .el-icon-success {
      color: #67C23A;
    }
    .el-icon-warning {
      color: #E6A23C;
    }
    h1 {
      font-size: 18px;
    }
  }
</style>
