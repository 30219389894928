
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
interface IRealNameForms {
  [key: string]: string;
  realname: string;
  idcard: string;
  mobile: string;
  captcha: string;
}
export default defineComponent({
  name: 'Shorten',
  setup () {
    const store = useStore()
    const { proxy }: any = getCurrentInstance()
    const sendSms = ref(false)
    const userInfo = computed(() => store.getters.GET_USER_INFO)
    const realNameForms = ref<IRealNameForms>({
      realname: '',
      idcard: '',
      mobile: '',
      captcha: ''
    })

    const submit = (): void => {
      proxy.$axios.post('/user/cert', realNameForms.value)
        .then((res: IResponse) => {
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
            store.commit('SET_USER_INFO', res.data)
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
          for (const i in realNameForms.value) {
            realNameForms.value[i] = ''
          }
        })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = realNameForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'cert'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    onMounted(() => {
      realNameForms.value.mobile = userInfo.value && userInfo.value.mobile
    })

    return {
      realNameForms,
      userInfo,
      submit,
      sendSms,
      handleSms
    }
  }
})
